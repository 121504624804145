import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import CommonOfferPrice from 'common/components/entities/OfferPriceNew'
import { FieldSlugEnum } from 'common/enums/FieldSlugEnum'
import { PaymentMethodEnum } from 'common/enums/PaymentMethodEnum'
import { BadRequest } from 'common/errors'
import { OfferPriceInterface } from 'common/types/entities/OfferPriceInterface'
import { CalculatePriceData } from 'common/types/shippingType'
import { optInFail } from 'publisher/actions/optInActions'
import StyleWrapper from 'publisher/components/core/StyleWrapper'
import { fetchOfferPricing } from 'publisher/pages/offer-page/utils/fetchOfferPricing'
import { useOptIn, usePage, usePayment } from 'publisher/store'
import { getFieldValueBySlug } from 'publisher/store/optIn/optInSelectors'
import pageSelectors from 'publisher/store/page/pageSelectors'
import {
  setActivePricePlanId,
  setOfferPricing,
} from 'publisher/store/payment/paymentActions'
import paymentSelectors, {
  getActivePaymentMethod,
  getCheckedCoupon,
  isOnlyPersonalAvailable,
  getCheckedBump,
  getOfferId,
  getOfferPricing,
} from 'publisher/store/payment/paymentSelectors'
import { useCalculatedPricePlans } from './useCalculatedPricePlans'

function OfferPrice({ entity }: { entity: OfferPriceInterface }) {
  const dispatch = useDispatch()
  const locale = usePage(pageSelectors.getLocale)
  const { pricePlans, activePlanId } = useCalculatedPricePlans()
  const coupon = usePayment(getCheckedCoupon)
  const bump = usePayment(getCheckedBump)
  const offerId = usePayment(getOfferId)
  const activePaymentMethod = usePayment(getActivePaymentMethod)
  const isVatNotChargeable =
    usePayment(isOnlyPersonalAvailable) ||
    activePaymentMethod === PaymentMethodEnum.RazorpayCard
  const taxNumber = useOptIn(state =>
    getFieldValueBySlug(state, FieldSlugEnum.TaxNumber),
  )
  const customerCountry = useOptIn(state =>
    getFieldValueBySlug(state, FieldSlugEnum.Country),
  )
  const offerPricing = usePayment(getOfferPricing)
  const product = usePayment(paymentSelectors.getProduct)
  const activeProductVariant = usePayment(
    paymentSelectors.getProductActiveVariant,
  )
  const productQuantity = usePayment(paymentSelectors.getProductQuantity)
  const selectedShipping = usePayment(paymentSelectors.getSelectedShipping)

  const bumpPlan = bump?.pricePlans[0]
  const productBump = bump?.product || null

  const togglePricePlanId = (pricePlanId: number) => {
    dispatch(setActivePricePlanId(pricePlanId))
  }

  const [abortController, setAbortController] = useState<AbortController>()

  useEffect(() => {
    if (!offerId || (!product?.id && !pricePlans.length)) return

    const fetchPriceData = async () => {
      try {
        const controller = new AbortController()
        setAbortController(controller)

        const offers: CalculatePriceData[] = pricePlans.map(pricePlan => ({
          quantity: 1,
          offer: offerId,
          coupon: coupon?.id ?? null,
          pricePlan: pricePlan.id,
          country: customerCountry ? customerCountry : null,
          taxNumber,
        }))

        // may or may not be included in general price plans list
        if (bumpPlan && !pricePlans.some(pp => pp.id === bumpPlan.id)) {
          offers.push({
            quantity: 1,
            offer: offerId,
            pricePlan: bumpPlan.id,
            country: customerCountry ? customerCountry : null,
            taxNumber,
          })
        }

        if (product?.id) {
          offers.push({
            product: product?.id,
            productVariant: activeProductVariant?.id,
            quantity: productQuantity,
            offer: offerId,
            coupon: coupon?.id ?? null,
            country: customerCountry ? customerCountry : null,
            taxNumber,
          })
        }

        if (productBump?.id) {
          offers.push({
            product: productBump?.id,
            quantity: 1,
            offer: offerId,
            coupon: null,
            country: customerCountry ? customerCountry : null,
            taxNumber,
          })
        }

        const newOfferPricings = await fetchOfferPricing(offers, controller)
        dispatch(setOfferPricing(newOfferPricings))
      } catch (error) {
        if (error instanceof BadRequest) {
          dispatch(optInFail({ fields: error.response.data.errors.fields }))
        }
      }
    }

    if (abortController?.signal) {
      abortController.abort('new_request_sent')
    }

    fetchPriceData()
  }, [
    product?.id,
    productQuantity,
    bump?.id,
    activeProductVariant,
    coupon,
    customerCountry,
    offerId,
    taxNumber,
  ])

  const mainOfferPricing = offerPricing?.find(
    ({ offerId: pricingOfferId, productId }) =>
      offerId === pricingOfferId && productId === product?.id,
  )

  const bumpOfferPricing = offerPricing?.find(
    ({ offerId: pricingOfferId, productId }) =>
      offerId === pricingOfferId && productId === productBump?.id,
  )

  const bumpPlanPricing = offerPricing?.find(
    ({ offerId: pricingOfferId, pricePlanId }) =>
      offerId === pricingOfferId && pricePlanId === bumpPlan?.id,
  )

  const pricePlansPricing = offerPricing?.filter(
    ({ offerId: pricingOfferId, pricePlanId }) =>
      pricePlans.findIndex(
        pricePlan => offerId === pricingOfferId && pricePlan.id === pricePlanId,
      ) !== -1,
  )

  return (
    <StyleWrapper
      margin={entity.margin}
      appearance={entity.appearance}
      mobileMargin={entity.mobileMargin}
    >
      <CommonOfferPrice
        attrId={entity.htmlAttrId}
        plans={pricePlans}
        activePlanId={activePlanId}
        bumpPlan={bumpPlan}
        coupon={coupon}
        mainOfferPricing={mainOfferPricing}
        bumpOfferPricing={bumpOfferPricing}
        pricePlansPricing={pricePlansPricing}
        bumpPlanPricing={bumpPlanPricing}
        selectedShipping={selectedShipping}
        isVatNotChargeable={isVatNotChargeable}
        togglePricePlan={togglePricePlanId}
        customDescription={entity.customDescription}
        product={product}
        productBump={productBump}
        locale={locale}
        nameFontSize={entity.nameFontSize}
        mobileNameFontSize={entity.mobileNameFontSize}
        nameFontStyle={entity.nameFontStyle}
        mobileNameFontStyle={entity.mobileNameFontStyle}
        nameFontWeight={entity.nameFontWeight}
        mobileNameFontWeight={entity.mobileNameFontWeight}
        nameFontFamily={entity.nameFontFamily}
        mobileNameFontFamily={entity.mobileNameFontFamily}
        nameLineHeight={entity.nameLineHeight}
        mobileNameLineHeight={entity.mobileNameLineHeight}
        nameColor={entity.nameColor}
        mobileNameColor={entity.mobileNameColor}
        amountFontSize={entity.amountFontSize}
        mobileAmountFontSize={entity.mobileAmountFontSize}
        amountFontStyle={entity.amountFontStyle}
        mobileAmountFontStyle={entity.mobileAmountFontStyle}
        amountFontWeight={entity.amountFontWeight}
        mobileAmountFontWeight={entity.mobileAmountFontWeight}
        amountFontFamily={entity.amountFontFamily}
        mobileAmountFontFamily={entity.mobileAmountFontFamily}
        amountLineHeight={entity.amountLineHeight}
        mobileAmountLineHeight={entity.mobileAmountLineHeight}
        amountColor={entity.amountColor}
        mobileAmountColor={entity.mobileAmountColor}
        descriptionFontSize={entity.descriptionFontSize}
        mobileDescriptionFontSize={entity.mobileDescriptionFontSize}
        descriptionFontStyle={entity.descriptionFontStyle}
        mobileDescriptionFontStyle={entity.mobileDescriptionFontStyle}
        descriptionFontWeight={entity.descriptionFontWeight}
        mobileDescriptionFontWeight={entity.mobileDescriptionFontWeight}
        descriptionFontFamily={entity.descriptionFontFamily}
        mobileDescriptionFontFamily={entity.mobileDescriptionFontFamily}
        descriptionLineHeight={entity.descriptionLineHeight}
        mobileDescriptionLineHeight={entity.mobileDescriptionLineHeight}
        descriptionColor={entity.descriptionColor}
        mobileDescriptionColor={entity.mobileDescriptionColor}
      />
    </StyleWrapper>
  )
}

export default OfferPrice
